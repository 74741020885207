import React,{useState} from 'react';
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { useDispatch, useSelector } from 'react-redux';
import { setData, setEmptyCart } from './cartSlice';
import axios from 'axios';
import OrderConfirmation from './OrderConfirmation';
import '../css/spinner.css';
 
function PayPalV2(props){
 
 const navigate = useNavigate();
 
 

 const setDataA = useDispatch();
 const emptyCart = useDispatch();
 const dataA = useSelector((state) => state.cart.data);
 const RX89TUV = useSelector((state) => state.cart.rx89tuv);
 const [loading, setLoading] = useState(false);


 const orderConfirmation = ()=>{
  console.log("in order confirmation");

  emptyCart(setEmptyCart([]));
  navigate("/order_confirmation",{replace:true});
 }

 const clientID_Live = 'AXpr_OY0EkeGd18PDtNcx5UQf_b5wKiABVXCxqtxFZt3f62ml2se07jN28RRB68PHoJcABg2NPCLTdys';
 const clientID_Sandbox = 'AYIOib7LgaOmHB7ejrTahvFQq3LINXLSqBn8yxJC1CDsJe2Yc2GC-MSNrR8NkS19b-WzSsmqd3QnJ6fg';

 const initialOptions = {
  "client-id": clientID_Live,
  currency: "CAD",
  intent: "capture",
  "disable-funding" : "card"
};



    return (
      
    
      <div className="paypal-button-format">
      {loading ? <div className="loading-div-background"><h1 className="loading-text">PROCESSING ORDER...</h1></div>:""}
        <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        createOrder={(data, actions) => {
          setLoading(true);
          return actions.order.create({
            purchase_units: [{
              amount: {
                currency_code: "CAD",
                value: props.total
              },
            }],
            // application_context: {
            //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
            // }
          });
        }}
        onApprove={(data, actions) => {
          // Capture the funds from the transaction
          return actions.order.capture().then(function(details) {
              //console.log(data);
            // Show a success message to your buyer
          // alert("Transaction completed by " + details.payer.name.given_name);
 
            // OPTIONAL: Call your server to save the transaction
            const customerData = {
              orderID: details,
              cartDetails: props.cartDetails,
              data,
              total:props.total,
              tax:props.tax,
              discountSwitch:props.discountSwitch,
              discount:props.discount,
              cuData:dataA,
              discountAmount:props.discountAmount,
              RX89TUV
            }
          
           axios.post('api/SendOrderController.php',customerData).then(response =>{ 
            //setLoading(true);
           
          
              //setTokenA(setToken(response.data.token));
             /* if(response.data.message == true && response.data.emailResult == true){
                orderConfirmation();
              }*/
              let message = response.data.message;
              let emailResult = response.data.emailResult;
              if(message == true && emailResult == true){
                setLoading(false);
                orderConfirmation();
              }
              
            })
          })
        }
      }/>
    </PayPalScriptProvider>
      </div>
      
    );
}

 

export default PayPalV2;
